import './App.css';
import { Link } from 'react-router-dom';
import img_preprocess from './assets/images/preprocess_esmtools.png';
import img_package from './assets/images/package_esmtools.png';


function App() {
  return (
    <div className="App">
      <div className="App-header">
        <h1>ESM Tools</h1>
        <p>ESM tools is a collection of tools developed at KU Leuven. The tools comprise a comprehensive suite designed to assist researchers with various aspects of conducting Experience Sampling Method (ESM) studies.</p>
      </div>
      <div className="Apps-container">
        {/* <h2 className="regular-title">Tools</h2> */}
        <div className="Apps-content">
          <div className="App-card">
              <Link to="https://preprocess.esmtools.com" target="_blank" rel="noopener noreferrer" className="accommodation-link">
                  <img src={img_preprocess} alt="preprocess website" className="accommodation-image" />
                  <div className="accommodation-info">
                      <h3>ESM Preprocessing Gallery</h3>
                      <p>Provides tools and resources for ESM data preprocessing, including R code, guidelines, and the esmtools R package.</p>
                  </div>
              </Link>
          </div>
          <div className="App-card">
              <Link to="https://package.esmtools.com" target="_blank" rel="noopener noreferrer" className="accommodation-link">
                  <img src={img_package} alt="package website" className="accommodation-image" />
                  <div className="accommodation-info">
                      <h3>esmtools R package</h3>
                      <p>Offers functions for preprocessing and analyzing EMA/ESM data, supporting insights and reporting.</p>
                  </div>
              </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
